import React from 'react';
import ClinicH from '../images/HEAF.jpg';
import Headm from '../images/HEAD.jpg';
import Head3 from '../images/headmedi.jpg';
import Head4 from '../images/head4.jpg';
import {Link} from 'react-router-dom';

function Slider(props) {
    return (
<>


<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    
  </div>
  <div class="carousel-inner">

  
    <div class="carousel-item active">
      <img src={Head3} class="d-block w-100" alt="head3"/>
    </div>
    <div class="carousel-item">
      <img src={Head4} class="d-block w-100" alt="Head4"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>



</>




        );
    }
    
    export default Slider;
    