import React from 'react';
import {Link} from 'react-router-dom';

function Main(props) {
    return (
<>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            
 
           
         

<iframe  className="iframe-fluid"  height="436"  id="gmap_canvas" src="https://maps.google.com/maps?q=Sarkar%20Medical%20Center,%20Karimpur&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
</div>

</div>
</div>


</>
);
}

export default Main;