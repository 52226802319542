import React from 'react';

import LabT from '../images/mission.jpg';
import Navbar from '../inc/Navbar';
import ScrollToTop from "react-scroll-to-top";

function Main(props) {
    return (
<>

<Navbar/>
<br/>
<br/>
<ScrollToTop Smooth top="100"/>
<br/>
<br/>
<h2 style={{textAlign: "center", color: "darkOrange"}}> We have been providing proper service to patients for 5 years</h2>
<br/>

<div className="album py-5">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-8" >

                       
            <img src={LabT} className="card-img-top"   alt="LabT" />
                                
                                <div className="d-flex justify-content-between align-items-center">
                               
                        
                            </div>
                               
                            </div>
                            <div className="col-md-6 mt-6" >
                        
                                <h6 className="card-text"> <b>Labtest Book</b></h6>
                                <p className="card-text">Our lab test report is very good, we will give the best to the patients.</p>
                                <button type="button" className="btn btn btn-light">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "darkCyan"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "darkCyan"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "darkCyan"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{textAlign: "center", color: "darkCyan"}} fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  style={{textAlign: "center", color: "darkCyan"}} fill="currentColor" class="bi bi-star-half" viewBox="0 0 16 16">
  <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z"/>
</svg> 
                                        </button>
                                        <br/>
                                        <br/>
                                        <h4 style={{textAlign: "center", color: "darkCyan"}} className="card-text"><center>Our Patient's Speak</center></h4>
 <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="10000">
    <div className="px-4 py-5 my-12 text-center">
        <h6 style={{textAlign: "center", color: "darkOrange"}}> The services of this clinic are great & responsible
</h6>
        <p className="btn btn btn-lg px-4 gap-3">Soumen Banerjee (Businessman)</p>
        </div>
     
    </div>
    <div className="carousel-item" data-bs-interval="2000">
    <div className="px-4 py-5 my-12 text-center">
        <h6 style={{textAlign: "center", color: "darkOrange"}}>I'm fine with showing the doctor at this clinic</h6>
        <p className="btn btn btn-lg px-4 gap-3">Mou Saha (House Wife)</p>
        </div>
      
    </div>
    <div class="carousel-item">
    <div className="px-4 py-5 my-12 text-center">
        <h6 style={{textAlign: "center", color: "darkOrange"}}>Got genuine medicine here, better than the rest of the place.</h6>
        <p  className="btn btn btn-lg px-4 gap-3">Sourav Sarkar (Govt. Services)</p>
        </div>
     
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

                                
                                <div className="d-flex justify-content-between align-items-center">
                         
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>


</>
);
}

export default Main;