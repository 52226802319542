import React from 'react';
import Chamber from '../images/small.jpg';
import PRest from '../images/PATIENTREST.jpg';
import PRest2 from '../images/PA2.jpg';

import Servicep1 from '../images/docto.jpg';
import Servocesp2 from '../images/DIAG.jpg';
import Medicines from '../images/medi.jpg';
import Diab from '../images/diab.jpg';
import Services3 from '../images/healthc.jpg';
import Dental from '../images/dental.jpg';


import ScrollToTop from "react-scroll-to-top";

import Navbar from '../inc/Navbar';

function Servicves() {
    return (
<>
<Navbar/>
<br/>
<br/>
<ScrollToTop Smooth top="100"/>

<br/>



                        
                        <div className="album py-5">
                       
                       <div className="container">
                       <div className="card mb-4 box-shadow">
                           <div className="row">
                          
                           <div className="col-md-6 ">
                           
                                   <div className="card-body">
                                   <h4 style={{textAlign: "center", color: "darkCyan"}} className="card-text"><center>Our Patient's Speak</center></h4>
                <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
                 <div class="carousel-indicators">
                   <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                   <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                   <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                 </div>
                 <div class="carousel-inner">
                   <div class="carousel-item active" data-bs-interval="10000">
                   <div className="px-4 py-5 my-12 text-center">
                       <h6 style={{textAlign: "center", color: "darkOrange"}}>The services of this clinic are great & responsible</h6>
                       <p className="btn btn btn-lg px-4 gap-3">Soumen Banerjee (Businessman)</p>
                       </div>
                    
                   </div>
                   <div className="carousel-item" data-bs-interval="2000">
                   <div className="px-4 py-5 my-12 text-center">
                       <h6 style={{textAlign: "center", color: "darkOrange"}}>I'm fine with showing the doctor at this clinic</h6>
                       <p className="btn btn btn-lg px-4 gap-3">Mou Saha (House Wife)</p>
                       </div>
                     
                   </div>
                   <div class="carousel-item">
                   <div className="px-4 py-5 my-12 text-center">
                       <h6 style={{textAlign: "center", color: "darkOrange"}}> Got genuine medicine here, better than the rest of the place</h6>
                       <p  className="btn btn btn-lg px-4 gap-3">Sourav Sarkar (Govt. Services)</p>
                       </div>
                    
                   </div>
                 </div>
                 <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                   <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                   <span class="visually-hidden">Previous</span>
                 </button>
                 <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                   <span class="carousel-control-next-icon" aria-hidden="true"></span>
                   <span class="visually-hidden">Next</span>
                 </button>
               </div>
                                     
                                           <div className="d-flex justify-content-between align-items-center">
                                              </div>
                                          
                                           </div>
                                       </div> 
                                   
                                 
                               <div className="col-md-6 ">
                             
                                   <div className="card-body">
                                   {/* <h4 className="card-text">Your data is 100% secure here</h4>
                                           <p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
                 <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                 <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
               </svg> Uniaone Family does not sell or share your data with any third party </p>
               <p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
                 <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                 <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
               </svg> <small> Uniaone Family follows stringent policies so that data isn’t compromised at any step</small></p>
               <p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
                 <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                 <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
               </svg>  We have a responsibility to make your data secure</p> */}
                <img src= {Chamber} className="card-img-top" alt="Chamber" />
               
               
                                           <div className="d-flex justify-content-between align-items-center">
                                              
                                               
                                         </div>
               
                                  
                                     
                                   </div>
                                   </div>
               
                                 
                               </div>
                               </div>
                               </div>
                             </div>          
                            

                             <h2 style={{textAlign: "center", color: "darkCyan"}}> Patient Wait Room</h2>
                             <br/>
<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={PRest} class="d-block w-100" alt="PRest"/>
    </div>
    <div class="carousel-item">
      <img src={PRest2} class="d-block w-100" alt="PRest2"/>
    </div>
   
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<br/>
<h2 style={{textAlign: "center", color: "darkCyan"}}> Our Services </h2>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-3 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Servicep1} className="card-img-top"   alt="Services" />
                            <div className="card-body">
                                
                                <h6 className="card-text"><center> <b>Consultation</b></center></h6>
                          
                                <div className="d-flex justify-content-between align-items-center">
                               
                                       
                                        
                                      
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Servocesp2} className="card-img-top"   alt="Services" />
                            <div className="card-body">
                                <h6 className="card-text"><b> <center>Diagnostics </center></b></h6>
                             
<div className="d-flex justify-content-between align-items-center">
                                
                                       
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Dental} className="card-img-top"   alt="Dental" />
                            <div className="card-body">
                                <h6 className="card-text"><b><center>Dental</center></b></h6>
                             
<div className="d-flex justify-content-between align-items-center">
                                
                                       
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Medicines} className="card-img-top"   alt="Medicines" />
                            <div className="card-body">
                                <h6 className="card-text"><b><center>Medicines</center></b></h6>
                        
<div className="d-flex justify-content-between align-items-center">
                                
                                       
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-3 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Diab} className="card-img-top"   alt="Diab" />
                            <div className="card-body">
                                <h6 className="card-text"><b><center>Diabetes </center></b></h6>
                             
<div className="d-flex justify-content-between align-items-center">
                                
                                       
                            </div>
                            </div>
                            </div>
                               
                            </div>
                       
                            <div className="col-md-3 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Services3} className="card-img-top"   alt="Services3" />
                            <div className="card-body">
                                <h6  className="card-text"><b><center>Healthcheckup</center></b></h6>
                             
                                <div className="d-flex justify-content-between align-items-center">
                                   
                               
                               
                       
                      
                                     
                                      
                                       
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                            <hr/>
</>

);
}

export default Servicves;