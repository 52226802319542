
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyC96vYoLmkbGKOD5_GKWEJXBRBzTTQvZgg",
    authDomain: "sarkar-medical.firebaseapp.com",
    projectId: "sarkar-medical",
    storageBucket: "sarkar-medical.appspot.com",
    messagingSenderId: "305503953545",
    appId: "1:305503953545:web:2bdbccae399217756d1c58",
    measurementId: "G-BXCFC2QM13"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;

