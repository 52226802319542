import React, { useEffect} from 'react';
import ReactGA from 'react-ga';
import Slider from '../inc/Slider';
import Navbar from '../inc/Navbar';
import Mapg from '../pages/Mapg';
import Servicep1 from '../images/docto.jpg';
import Servocesp2 from '../images/DIAG.jpg';
import Medicines from '../images/medi.jpg';
import Diab from '../images/diab.jpg';
import Services3 from '../images/healthc.jpg';
import Dental from '../images/dental.jpg';
import DoctorC from '../images/small2.jpg';
import DoctorC1 from '../images/small.jpg';
import ScrollToTop from "react-scroll-to-top";



function Main(props) {
    useEffect(() => {
        ReactGA.initialize('UA-234087712-1')

        //to report page view
        ReactGA.pageview(window.location.pathname + window.location.search)

    },[])
    return (
<>

<Navbar/>
<br/>
<br/>
<Slider/>
<br/>
<ScrollToTop Smooth top="100"/>

<marquee style={{ color: "darkCyan"}}><h3> Welcome Sarkar Medical Center, Call for any health help. Help Line <a href="tel: +91 9153525658"  className="btn btn- btn-primary">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg>+919153525658*</a></h3> </marquee>
<div className="album py-1">
        
        <div className="container">

            <div className="row">
            <div className="col-md-7">
              
                    <div className="card-body">
            
                    <h2 style={{textAlign: "cent", color: "DarkSlateBlue"}} className="card-text mb-2">Our Services</h2>
                           
                            <p style={{textAlign: "cent", color: "DimGray"}}  className="card-text">We provide proper service to patients.</p>
                    
                    </div>

                  
                </div>
                </div>
                </div>
                </div>

                <div className="album py-2">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-2 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Servicep1} className="card-img-top"   alt="Services" />
                            <div className="card-body">
                                
                                <h6 className="card-text"><center> <b>Consultation</b></center></h6>
                          
                                <div className="d-flex justify-content-between align-items-center">
                               
                                       
                                        
                                      
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-2 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Servocesp2} className="card-img-top"   alt="Services" />
                            <div className="card-body">
                                <h6 className="card-text"><b> <center>Diagnostics </center></b></h6>
                             
<div className="d-flex justify-content-between align-items-center">
                                
                                       
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-2 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Dental} className="card-img-top"   alt="Dental" />
                            <div className="card-body">
                                <h6 className="card-text"><b><center>Dental</center></b></h6>
                             
<div className="d-flex justify-content-between align-items-center">
                                
                                       
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-2 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Medicines} className="card-img-top"   alt="Medicines" />
                            <div className="card-body">
                                <h6 className="card-text"><b><center>Medicines</center></b></h6>
                        
<div className="d-flex justify-content-between align-items-center">
                                
                                       
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-2 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Diab} className="card-img-top"   alt="Diab" />
                            <div className="card-body">
                                <h6 className="card-text"><b><center>Diabetes </center></b></h6>
                             
<div className="d-flex justify-content-between align-items-center">
                                
                                       
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-2 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={Services3} className="card-img-top"   alt="Services3" />
                            <div className="card-body">
                                <h6  className="card-text"><b><center>Healthcheckup</center></b></h6>
                             
                                <div className="d-flex justify-content-between align-items-center">
                                   
                               
                               
                                 {/* <button type="button" className="btn btn btn-dark">
                                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button>
                                                             
                                        < FacebookShareButton url="https://www.techuniaone.com/gym" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.techuniaone.com/gym" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.techuniaone.com/gym" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.techuniaone.com/gym" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.techuniaone.com/gym" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton> */}
                      
                                     
                                      
                                       
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                        
         <div className="album py-5">
        
        <div className="container">
        <div className="card mb-4 box-shadow">
            <div className="row">
           
            <div className="col-md-6 ">
            
                    <div className="card-body">
                    
                       <img src= {DoctorC} className="card-img-top" alt="DoctorC" />
                            <div className="d-flex justify-content-between align-items-center">
                               </div>
                           
                            </div>
                        </div> 
                    
                  
                <div className="col-md-6 ">
              
                    <div className="card-body">
                    {/* <h4 className="card-text">Your data is 100% secure here</h4>
                            <p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
</svg> Uniaone Family does not sell or share your data with any third party </p>
<p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
</svg> <small> Uniaone Family follows stringent policies so that data isn’t compromised at any step</small></p>
<p className="card-text"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
  <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z"/>
</svg>  We have a responsibility to make your data secure</p> */}
 <h4 style={{textAlign: "center", color: "darkCyan"}} className="card-text"><center>Our Patient's Speak</center></h4>
 <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="10000">
    <div className="px-4 py-5 my-12 text-center">
        <h6 style={{textAlign: "center", color: "darkOrange"}}> The services of this clinic are great & responsible
</h6>
        <p className="btn btn btn-lg px-4 gap-3">Soumen Banerjee (Businessman)</p>
        </div>
     
    </div>
    <div className="carousel-item" data-bs-interval="2000">
    <div className="px-4 py-5 my-12 text-center">
        <h6 style={{textAlign: "center", color: "darkOrange"}}>I'm fine with showing the doctor at this clinic</h6>
        <p className="btn btn btn-lg px-4 gap-3">Mou Saha (House Wife)</p>
        </div>
      
    </div>
    <div class="carousel-item">
    <div className="px-4 py-5 my-12 text-center">
        <h6 style={{textAlign: "center", color: "darkOrange"}}>Got genuine medicine here, better than the rest of the place.</h6>
        <p  className="btn btn btn-lg px-4 gap-3">Sourav Sarkar (Govt. Services)</p>
        </div>
     
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

                            <div className="d-flex justify-content-between align-items-center">
                               
                                
                          </div>

                   
                      
                    </div>
                    </div>

                  
                </div>
                </div>
                </div>
                </div>
    <hr/>
<Mapg/>
<hr/>
</>

);
}

export default Main;