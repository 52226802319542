import React from 'react';

import './App.css';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';


// import Footer from "./components/inc/Footer";
import Main from "./components/pages/Main";
import Find from "./components/pages/Find";
import Services from "./components/pages/Services";
import Footer from "./components/inc/Footer";
import Medicine from "./components/pages/Medicine";
import Labtest from "./components/pages/Labtest";
import Contact from "./components/pages/Contact";

import PaginationOld from './components/pages/PaginationOld';


function App() {
  return (

    <Router>
    
      <div>
        {/* <Navbar/> */}
       
        
        <Routes>

        <Route axact path="/" element={<Main/>}/>
        <Route axact path="/FindDoctors" element={<Find/>}/>
        <Route axact path="/Services" element={<Services/>}/>
        <Route axact path="/Medicine" element={<Medicine/>}/>
        <Route axact path="/Labtest" element={<Labtest/>}/>
        <Route axact path="/Contact" element={<Contact/>}/>
      
        <Route axact path="/PaginationOld" element={<PaginationOld/>}/>

       

    </Routes>
    <Footer/>
  
    </div>

    </Router>
   
  );
}

export default App;
