import React from 'react';
// import './Footer.css';
import {Link} from 'react-router-dom';
import Logofooter from '../images/smc1.jpg';

function Footer() {
    return(
        
    
     <div className="section footer bg-white text-dark">
         <br></br>
         <br/>
        
    <div className="album py-3">
        
        <div className="container">

            <div className="row">
            <div className="col-md-4">
              
                 <h6>SARKAR MEDICAL CENTER</h6>
                   <hr/>
                     <ul className="list-unstyled">
                    
           
            <li className="mb-2"  ><small>Karimpur Bazar, Beside L.I.C Office, <br/>
            Nadia, West Bengal, India 741152</small></li>
            {/* <li className="mb-2" ><small>Blog</small></li> */}
          
            <li className="mb-2" ><small>                                <a href="tel: +91 9153525658">   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg> +91 9153525658*</a>
                           </small></li>
          
            <li className="mb-2"><small><a href="tel: +91 6295452231">   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg> +91 6295452231*</a></small></li>
          
          </ul>
          </div>
          <div className="col-md-2">
                     <h6>FOR PATIENT</h6>
                     <hr/>
                     <ul class="list-unstyled">
                       <Link to ="/FindDoctors">
            <li className="mb-2"><small>All Doctor</small></li></Link>
            <Link to="/Labtest">
            <li className="mb-2"><a><small>Labtest</small></a></li></Link>
            <Link to="/Medicine">
            <li className="mb-2"><a ><small>Medicine</small></a></li></Link>
            
            
          </ul>
                     {/* <div><Link to="/">Career </Link></div>
                     <div><Link to="/">Blog</Link></div>
                     <div><Link to="/">Uniaone</Link></div> */}
                 </div>
                 <div className="col-md-3">
                     <h6>CONTACT</h6>
                     <hr/>
                     <ul class="list-unstyled">
                       <Link to="/Contact">
                     <li className="mb-2" ><small>Contact</small></li></Link>
                    
                      

                     </ul>
                 </div>
                 
          
          
          <div className="col-md-3">
                 <h6>SOCIAL LINKS</h6>
                   <hr/>
                     <ul className="list-unstyled">
                      
            <li className="mb-2"> <a href="https://www.facebook.com/keshab.sarkar.94">
                 <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
</svg> <small> Facebook</small></a> </li>
           
          
          
           
            
           <br/>
           
          </ul>
          
          </div>

        
          
                 </div>
                 </div>
                 </div>
               
               
                 <br></br><br/>

    <div className="album py-3">
        
        <div className="container">

            <div className="row">
            <div className="col-md-4">
      <Link to="/">
                 <img src={Logofooter} class="img-fluid" alt="Logofooter"/> </Link>
                 </div>
                 <div className="col-md-4">
                 <p> Copyright © 2023, Sarkar Medical </p>
                
                 </div>
                 <div className="col-md-4">
                <small>Development & Design By  <a href="https://www.mrindiancoder.com/" target='_blank'>www.mrindiancoder.com </a></small>
                 </div>
                 
                 </div>
                 </div>
                 </div>
                <br></br>

             </div>
             
            
         
         
     
    );
}
export default Footer;